import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import Logo from './images/KAHStudios.png';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';


function HeroSection() {
  const [countdownTime, setCountdownTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date('March 1, 2025 00:00:00 UTC').getTime();


    const interval = setInterval(() => {
  const now = new Date().getTime();
  const distance = targetDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  setCountdownTime({ days, hours, minutes, seconds });

  if (distance < 0) {
    clearInterval(interval);
  }
}, 1000);


    return () => clearInterval(interval);
  }, []);

   const renderCountdownElement = (value, label) => (
    <div className="countdown-element" >
      <span className="countdown-number">{value}</span>
      <span className="countdown-label">{label}</span>
    </div>
  );

  return (
    <div className="hero-container">
      <div className="hero-content">
        <h2>Website is under maintenance</h2>
       <h1>We're <span className="highlight">Re-Launching</span> Soon</h1>
        <div className="countdown">
          {renderCountdownElement(countdownTime.days, 'Days')}
          {renderCountdownElement(countdownTime.hours, 'Hours')}
          {renderCountdownElement(countdownTime.minutes, 'Minutes')}
          {renderCountdownElement(countdownTime.seconds, 'Seconds')}
        </div>
        <p>Read some of our comics</p>
       <div className="download-buttons">
  <a href="/Enigma1.pdf" className="btn btn-download" download="Enigma1.pdf">Enigma #1</a>
  <a href="/Faith1.pdf" className="btn btn-download" download="Faith1.pdf">Faith #1</a>
  <a href="/Tsaro1.pdf" className="btn btn-download" download="Tsaro1.pdf">Tsaro #1</a>
</div>

      </div>
      <div className="social-media">
  <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
    <FaFacebookF className="social-icon" />
  </a>
  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
    <FaTwitter className="social-icon" />
  </a>
  <a href="https://www.instagram.com/kahstudios?igsh=ODA1NTc5OTg5Nw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
    <FaInstagram className="social-icon" />
  </a>
  <a href="https://www.linkedin.com/company/kahstudios" target="_blank" rel="noopener noreferrer">
    <FaLinkedin className="social-icon" />
  </a>
</div>

      <div className="logo-container">
        <img src={Logo} alt="KAH Studios" />
      </div>
    </div>
  );
}

export default HeroSection;
